import React, { useState } from 'react';
import styled from '@emotion/styled';
import sizes from 'style/sizes';
import { mq, contentWidthPc, baseTransitionSecond } from 'style/variables';
import ServicePanel from 'components/molecules/servicePanel';
import { EnHeading } from 'components/atoms/enHeading';
import { graphql, useStaticQuery } from 'gatsby';
import { InView } from 'react-intersection-observer';
import { ServicePanelQuery } from 'types/graphql-types';
import ServicesImage from 'assets/images/top/img-services.png';
import { contentWrapper } from 'style/mixin';
import { IndexLink } from 'components/molecules/indexLink';
import fonts from 'style/fonts';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { TextButton } from 'components/atoms/textButton';

type ServiceProps = {
  top?: boolean;
  pageLang?: 'ja' | 'en';
  delay?: number;
};

const Container = styled('div')<ServiceProps>`
  position: relative;
  ${mq.onlypc} {
    margin-top: sizes.margin[180];
  }
  ${mq.onlysp} {
    ${contentWrapper};
    margin-top: sizes.margin[100];
    overflow: hidden;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.03;
  }
`;

const ServiceInner = styled('div')`
  ${contentWrapper}
  position: relative;
  margin: 0 auto;
  width: 100%;
  ${mq.onlypc} {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${mq.onlysp} {
    padding: 0;
  }
`;

const ServiceTitleWrap = styled('div')`
  ${mq.onlypc} {
    padding-top: 30px;
  }
  ${mq.onlysp} {
    text-align: left;
    margin-bottom: ${sizes.margin[49]};
  }
`;

const ServiceTopTitle = styled('div')<Pick<ServiceProps, 'pageLang'>>`
  ${mq.onlypc} {
    width: fit-content;
  }
  ${mq.onlysp} {
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
`;

const ServiceTopImage = styled('div')`
  position: relative;
  ${mq.onlypc} {
    flex-shrink: 0;
    max-width: 690px;
    height: 100%;
    margin-right: -40px;
  }
  ${mq.onlysp} {
    width: 100%;
  }
`;

const ServiceTitleText = styled('div')`
  width: 100%;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.8em;
  padding-top: 20px;
  letter-spacing: 0.11em;
  font-family: ${fonts.jaGothic};
  ${mq.onlysp} {
    width: 234px;
    padding-top: 32px;
  }
`;

const ServicePanelsWrap = styled(InView)`
  position: relative;
`;

const ServicePanels = styled('ul')`
  display: flex;
  width: 100%;
  ${mq.onlypc} {
    justify-content: space-between;
  }
  ${mq.onlysp} {
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -${sizes.margin[16]};
    width: calc(100% + ${sizes.margin[16]});
    padding: 0 32px;
  }
`;

const ServicePanelItem = styled('li')<ServiceProps>`
  opacity: 0;
  transform: translateY(20px);
  transition: opacity ${baseTransitionSecond * 2}s ease ${props => props.delay && props.delay * 0.2}s,
    transform ${baseTransitionSecond * 2}s ease ${props => props.delay && props.delay * 0.2}s;
  will-change: transform, opacity;
  ${mq.onlypc} {
    width: ${(206 / contentWidthPc) * 100}%;
  }
  ${mq.onlysp} {
    width: calc(135% / 310 * 100);
    margin-left: ${sizes.margin[16]};
    margin-top: ${sizes.margin[16]};
  }
  .is-shown & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SectionLinkWrapper = styled.div`
  position: relative;
  ${mq.onlypc} {
    padding-right: 8px;
    margin-top: 60px;
  }
  ${mq.onlysp} {
    margin-top: 50px;
  }
`;

const SectionLink = styled(IndexLink)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;

  ${mq.onlysp} {
    position: relative;
  }
`;

const title = {
  ja: 'ビジネスソリューションとしてのデザイン',
  en: 'Design as Business Solution',
} as const;

const Service: React.FC<ServiceProps> = props => {
  const services: ServicePanelQuery = useStaticQuery(
    graphql`
      query ServicePanel {
        allShifterServicesData(
          filter: { title: { rendered: { nin: ["dummy", null] } } }
          sort: { fields: acf___show_num, order: ASC }
        ) {
          edges {
            node {
              id
              slug
              acf {
                icon
              }
              title {
                rendered
              }
            }
          }
        }
      }
    `
  );
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      {!props.top ? (
        <ServicePanelsWrap
          className={isShow ? 'is-shown' : undefined}
          onChange={inView => {
            if (inView) {
              setIsShow(true);
            }
          }}
        >
          <ServicePanels>
            {services.allShifterServicesData.edges?.map((service, idx) => (
              <ServicePanelItem key={service.node.id} delay={idx + (props.delay ? props.delay * 2 : 0)}>
                <ServicePanel
                  lang={props.pageLang}
                  slug={service.node.slug || ''}
                  icon={service.node.acf?.icon || ''}
                  txt={service.node.title?.rendered || ''}
                />
              </ServicePanelItem>
            ))}
          </ServicePanels>
        </ServicePanelsWrap>
      ) : (
        <Container>
          <ServiceInner>
            <ServiceTitleWrap>
              <ServiceTopTitle pageLang={props.pageLang}>
                <EnHeading>Services</EnHeading>
                <ServiceTitleText>{title[props?.pageLang || 'ja']}</ServiceTitleText>
                <SectionLinkWrapper>
                  <SectionLink to={`${props.pageLang === 'en' ? '/en' : ''}/services`}>
                    <TextButton isBlock>Our Services</TextButton>
                  </SectionLink>
                </SectionLinkWrapper>
              </ServiceTopTitle>
            </ServiceTitleWrap>
            <FadeUpSection>
              <ServiceTopImage>
                <img src={ServicesImage} alt="Services" />
              </ServiceTopImage>
            </FadeUpSection>
          </ServiceInner>
        </Container>
      )}
    </>
  );
};

Service.defaultProps = {
  top: false,
  pageLang: 'ja',
};

export default Service;
