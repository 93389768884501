import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import colors from 'style/colors';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { Arrow } from 'components/atoms/arrow';
import { mq, baseTransitionSecond } from 'style/variables';
import { IndexLink } from 'components/molecules/indexLink';
import { arrowHoverKeyFrame, arrowHoverKeyFramePrev } from 'style/mixin';

type TextButtonProps = {
  icon?: 'arrow' | 'plus';
  arrowDirection?: 'left' | 'right';
  href?: string;
  to?: string;
  blank?: boolean;
  isBlock?: boolean;
  size?: 'small';
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) &
    ((event: React.MouseEvent<HTMLAnchorElement>) => void);
  isindex?: boolean;
};

const Container = styled.div`
  font-size: 0;
`;

const Body = styled.a<TextButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: baseline;
  font-family: ${fonts.enGothic};
  align-items: center;

  font-size: ${props => (props.size === 'small' ? '12px' : '15px')};
  line-height: 1;
  letter-spacing: 0.075em;
`;

const ArrowWrapperLeft = styled(Arrow)`
  position: relative;
  width: 24px;
  height: 24px;
  ${mq.onlypc} {
    a:hover & {
      animation: ${arrowHoverKeyFramePrev} ${baseTransitionSecond * 1.5}s ease both;
    }
  }
  * + & {
    margin-left: ${sizes.margin[17]};
  }
`;
const ArrowWrapper = styled(Arrow)`
  position: relative;
  width: 24px;
  height: 24px;
  ${mq.onlypc} {
    a:hover & {
      animation: ${arrowHoverKeyFrame} ${baseTransitionSecond * 1.5}s ease both;
    }
  }
  * + & {
    margin-left: ${sizes.margin[17]};
  }
`;

const Plus = styled.div`
  position: relative;
  width: 13px;
  height: 13px;
  margin-left: ${sizes.margin[17]};
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: ${colors.black01};
  }
  &::before {
    width: 100%;
    height: 1px;
  }
  &::after {
    width: 1px;
    height: 100%;
  }
`;

const Text = styled.span`
  display: block;
  * + & {
    margin-left: ${sizes.margin[17]};
  }
  ${mq.onlypc} {
    transition: letter-spacing ${baseTransitionSecond}s ease;
  }
  a:hover &,
  button:hover & {
    letter-spacing: 2px;
  }
`;

export const TextButton: React.FC<TextButtonProps> = ({ arrowDirection, isindex, ...props }) => {
  if (props.to) {
    const ContainerLink = isindex ? Body.withComponent(IndexLink) : Body.withComponent(Link);
    return (
      <Container>
        <ContainerLink {...props} to={props.to}>
          {arrowDirection === 'left' && <ArrowWrapperLeft arrowDir={arrowDirection} />}
          <Text>{props.children}</Text>
          {arrowDirection !== 'left' && <ArrowWrapper arrowDir={arrowDirection} />}
        </ContainerLink>
      </Container>
    );
  } else if (props.href) {
    return (
      <Container>
        <Body {...props} href={props.href} target={props.blank ? '_blank' : '_self'}>
          <Text>{props.children}</Text>
          <ArrowWrapper />
        </Body>
      </Container>
    );
  } else if (props.isBlock) {
    const ContainerLink = Body.withComponent('div');
    return (
      <Container>
        <ContainerLink>
          <Text>{props.children}</Text>
          {props.icon === 'plus' ? <Plus /> : <ArrowWrapper />}
        </ContainerLink>
      </Container>
    );
  } else {
    const ContainerLink = Body.withComponent('button');
    return (
      <Container>
        <ContainerLink {...props} onClick={props.onClick}>
          <Text>{props.children}</Text>
          <Plus />
        </ContainerLink>
      </Container>
    );
  }
};
