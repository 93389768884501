import styled from '@emotion/styled';
import { EnHeading } from 'components/atoms/enHeading';
import { ProjectCard } from 'components/molecules/projectCard';
import ServiceSectionContent from 'components/molecules/serviceSectionContent';
import { TextSet } from 'components/molecules/textSet';
import { ContentInner } from 'components/organisms/contentInner';
import { InquiryBlock } from 'components/organisms/inquiryBlock';
import RangeSlide from 'components/organisms/rangeSlide';
import Service from 'components/organisms/service';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import React, { useEffect } from 'react';
import colors from 'style/colors';
import sizes from 'style/sizes';
import { mq, breakpoint } from 'style/variables';
import { ServiceIndexEnQuery } from 'types/graphql-types';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpItem from 'components/organisms/fadeUpItem';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { contentWrapper } from 'style/mixin';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: ServiceIndexEnQuery;
}
const TitleArea = styled('div')`
  ${contentWrapper}
`;
const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;
const EnHeadingTitle = styled(EnHeading)`
  text-align: center;
`;

const Main = styled(ContentInner)`
  ${mq.onlypc} {
    padding-bottom: ${sizes.margin[64]}px;
  }
`;

const Anchor = styled.div`
  margin-bottom: ${sizes.margin[80]};
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[64]};
  }
`;

const Section = styled.section`
  padding: ${sizes.margin[100]} 0;
  margin-bottom: 4px;

  ${mq.onlysp} {
    padding: ${sizes.margin[80]} 0;
  }
  &:nth-of-type(odd) {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${colors.gray03};
      z-index: -1;
    }
  }
`;

const ServiceSlide = styled(RangeSlide)`
  padding-top: ${sizes.margin[64]};
  ${mq.onlysp} {
    padding-top: ${sizes.margin[48]};
  }
`;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const services = data.allShifterServicesData.edges;
  const projects = data.allShifterProjectsData.edges;

  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Services'} en />
      <TitleArea>
        <TitleSection>
          <EnHeading>Services</EnHeading>
        </TitleSection>
        <Main>
          <FadeUpItem delay={0.6}>
            <TextSet>
              <EnHeadingTitle>Design as Business Solution</EnHeadingTitle>
            </TextSet>
          </FadeUpItem>
        </Main>

        <Anchor>
          <Service pageLang={'en'} />
        </Anchor>
      </TitleArea>

      {services?.map((service, idx) => (
        <FadeUpSection key={idx}>
          <Section id={service.node?.slug || ''}>
            <ServiceSectionContent
              title={service.node?.title?.rendered || 'NO TITLE'}
              heading={service.node?.acf?.title_en || 'NO TITLE'}
              text={service.node?.acf?.text_en || 'NO TEXT'}
            />
            {(() => {
              const showProjects = projects?.filter(project =>
                project.node?.acf?.services?.some(elm => elm?.post_title === service.node?.title?.rendered)
              );
              if (showProjects?.length && showProjects.length > 0) {
                return (
                  <ServiceSlide perView={3}>
                    {showProjects?.map(showProject => (
                      <ProjectCard
                        key={showProject.node?.id}
                        imgpath={showProject.node?.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                        title={showProject.node?.acf?.headline_en || 'No title'}
                        client={showProject.node?.acf?.client_abbr?.en || undefined}
                        work={showProject?.node?.acf?.product?.en || undefined}
                        tags={(showProject.node?.acf?.outline as string[]) || undefined}
                        to={`/en/projects/${showProject.node?.slug}`}
                        size={'small'}
                        isEn
                      />
                    ))}
                  </ServiceSlide>
                );
              }
            })()}
          </Section>
        </FadeUpSection>
      ))}

      <InquiryBlock en />
    </>
  );
};

export const pageQuery = graphql`
  query ServiceIndexEn {
    site {
      siteMetadata {
        title
      }
    }
    allShifterServicesData(
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      sort: { fields: acf___show_num, order: ASC }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            icon
            text
            text_en
            title
            title_en
          }
        }
      }
    }
    allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            headline_en
            client_abbr {
              en
              ja
            }
            product {
              ja
              en
            }
            services {
              post_title
            }
            outline
            thumb
          }
          slug
        }
      }
    }
  }
`;

export default Pages;
