import styled from '@emotion/styled';
import Img from 'components/atoms/img/';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { baseTransitionSecond, mq } from 'style/variables';
import { text } from 'style/mixin';

type ServicePanelProps = {
  top?: boolean;
  slug: string;
  icon: string;
  txt: string;
  lang?: 'ja' | 'en';
};

const Container = styled(AnchorLink)`
  display: block;
`;

const ServicePanelInner = styled('div')<Pick<ServicePanelProps, 'top'>>`
  padding-bottom: ${props => (!props.top ? sizes.margin[17] : '32px')};
  ${mq.onlysp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 1.2rem;
  }
`;

const ServicePanelImg = styled(Img)``;

const ServicePanelIcon = styled('div')`
  ${mq.onlysp} {
    padding-bottom: ${111 / 148}%;
    width: 100%;
  }
`;

const ServicePanelTxt = styled('div')<Pick<ServicePanelProps, 'top'>>`
  position: relative;
  font-family: ${fonts.enGothic};
  text-align: center;
  letter-spacing: ${0.91 / 12}em;
  white-space: nowrap;
  ${text(12, 1.6)};
  ${props => props.top && `padding-top: 37px;`};
  ${props => !props.top && `order: 1;`}
  ${mq.onlysp} {
    word-break: break-word;
    white-space: normal;
    padding-top: 1rem;
    flex-grow: 1;
    letter-spacing: 0.4px;
  }
  br {
    ${mq.onlypc} {
      display: none;
    }
  }
`;

const AnchorArrow = styled('div')<Pick<ServicePanelProps, 'top'>>`
  position: relative;
  width: 15px;
  height: 7px;
  margin: ${sizes.margin[17]} auto 0;
  overflow: hidden;
  opacity: 0.6;
  transition: ${baseTransitionSecond}s ease;
  ${mq.onlypc} {
    a:hover & {
      transform: translateY(50%);
    }
  }
  ${mq.onlysp} {
    margin: 12px 0 auto;
    ${props => props.top && `order: 2;`}
  }
  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    height: 1px;
    background-color: ${colors.black01};
  }
  &::before {
    width: 62%;
    left: 0;
    transform-origin: left top;
    transform: rotate(35deg);
  }
  &::after {
    width: 65%;
    right: 0;
    transform-origin: right top;
    transform: rotate(-35deg);
  }
`;

const ServicePanel: React.FC<ServicePanelProps> = props => {
  const { txt, slug, top, lang } = props;
  return (
    <>
      {top ? (
        <div>
          <ServicePanelInner top={props.top}>
            <ServicePanelTxt top={props.top}>
              <span>{txt}</span>
            </ServicePanelTxt>
          </ServicePanelInner>
        </div>
      ) : (
        <Container to={`${lang === 'en' ? '/en' : ''}/services/#${slug}`}>
          <ServicePanelInner top={props.top}>
            <ServicePanelTxt top={props.top}>
              <span>{txt}</span>
            </ServicePanelTxt>
            <AnchorArrow top={props.top} />
          </ServicePanelInner>
        </Container>
      )}
    </>
  );
};

ServicePanel.defaultProps = {
  top: false,
  lang: 'ja',
};

export default ServicePanel;
