import styled from '@emotion/styled';
import React from 'react';
import sizes from 'style/sizes';
import { Text } from 'components/atoms/text';
import { EnHeading } from 'components/atoms/enHeading';
import { mq } from 'style/variables';
import { contentWrapper, text } from 'style/mixin';
import fonts from 'style/fonts';

type ServiceSectionContentProps = {
  title: string;
  heading: string;
  text: string;
};

const SectionContent = styled.div`
  ${contentWrapper}
  ${mq.onlypc} {
    display: flex;
    justify-content: space-between;
  }
`;
const SectionHeadingBlock = styled.div`
  ${mq.onlypc} {
    width: ${(334 / 1160) * 100}%;
  }
`;
const SectionHeading = styled(EnHeading)`
  ${text(20, 1.58)};
  font-family: ${fonts.enGothic};
  letter-spacing: 0.07em;
  ${mq.onlysp} {
    ${text(20, 1.58)};
  }
`;
const SectionTextBlock = styled.div`
  ${mq.onlypc} {
    width: ${(747 / 1160) * 100}%;
  }
  ${mq.onlysp} {
    margin-top: 54px;
  }
`;
const SectionTextHeading = styled(EnHeading)`
  ${text(40, 1.48)}
  font-weight: 400 !important; // textのmixinによってweightが上書きされるためさらに上書き、加えてEnHeading内部でimportantが使用されているため泣く泣くimportantで上書き
  ${mq.onlysp} {
    ${text(24, 1.48)}
    font-weight: 400 !important; // textのmixinによってweightが上書きされるためさらに上書き、加えてEnHeading内部でimportantが使用されているため泣く泣くimportantで上書き
  }
`;
const SectionText = styled(Text)`
  padding-top: ${sizes.margin[48]};
  ${mq.onlysp} {
    padding-top: ${sizes.margin[32]};
  }
`;

const ServiceSectionContent: React.FC<ServiceSectionContentProps> = props => {
  return (
    <SectionContent>
      <SectionHeadingBlock>
        <SectionHeading size={'middle'}>{props.title}</SectionHeading>
      </SectionHeadingBlock>
      <SectionTextBlock>
        <SectionTextHeading size={'middle'}>{props.heading}</SectionTextHeading>
        <SectionText>{props.text}</SectionText>
      </SectionTextBlock>
    </SectionContent>
  );
};

export default ServiceSectionContent;
